<template>
  <div class="section-block">
    <div class="title">
      <div class="left">
        <img
          style="width: 24px; height: 24px"
          src="@/assets/imgs/section-left-icon.png"
        />
        {{ title }}
      </div>
      <div class="right">
        <img
          style="width: 64px; height: 64px"
          src="@/assets/imgs/section-right-icon.png"
        />
      </div>
    </div>
    <div class="content"><slot></slot></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["title"],
};
</script>

<style scoped lang="scss">
.section-block {
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 24px;
  .title {
    height: 80px;
    background: linear-gradient(90deg, #f2fcff 0%, #e5efff 100%);
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 64px;
    .left {
      display: flex;
      align-items: center;
      font-weight: bold;
      img {
        margin-right: 16px;
      }
    }
    .right {
    }
  }
  .content {
    padding: 15px;
    background: #fff;
  }
}
</style>
